<template>
  <b-card-code title="Payer credit">
    <b-card-text class="mt-1">
      <span>Ajouter payment :</span>
    </b-card-text>
    <b-form-group
        label="Montant"
    >
        <b-form-input
            v-model="payment.amount"
            placeholder="Montant"
            type="text"
        />
    </b-form-group>
    <b-button
        variant="primary"
        type="submit"
        @click.prevent="addPayment"
    >
        Enregistrer
    </b-button>
  </b-card-code>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axiosIns from '@/libs/axios'


export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      
      payment: {
        customer: null,
        amount: null,
      },

    }
  },
  created() {
  },
  methods: {
   

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },

    async addPayment() {
      try {
        this.payment.customer = this.$route.params.id
        await axiosIns.post('/payments/create/', this.payment)
        setTimeout(() => {
          this.showToast('success', 'top-center', 'Payment ajouté avec succès')
        }, 1000)
        this.$router.push('/credits/')
      } catch (error) {
        setTimeout(() => {
          this.showToast('danger', 'top-center', error.toString())
        }, 1000)
      }
    },
   
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

</style>
